export interface ExtendedPointerEvent {
  pointerId: number;
  target: {
    setPointerCapture: (pointerId: number) => void;
  };
}

export interface PIXIPointerEvent {
  data: {
    originalEvent: ExtendedPointerEvent;
  };
}

/**
 * Capture the mouse pointer so that all subsequent mouse events are directed to it (until the mouse button is released).
 */
export function captureMouse(event: ExtendedPointerEvent) {
  if (event?.target?.setPointerCapture && event?.pointerId !== undefined) {
    event.target.setPointerCapture(event.pointerId);
  }
}

/**
 * Capture the mouse pointer using the wrapped PointerEvent PIXI gives us.
 */
export function pixiCaptureMouse(event: PIXIPointerEvent) {
  captureMouse(event?.data?.originalEvent);
}
