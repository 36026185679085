// Gets the range of stenosis matching the levels we have in the legend
export function getStenosisRiskLevel(stenosisValue: number) {
  if (stenosisValue === 0) {
    return 'minimal';
  } else if (stenosisValue >= 1 && stenosisValue < 50) {
    return 'mild';
  } else if (stenosisValue >= 50 && stenosisValue < 70) {
    return 'moderate';
  } else if (stenosisValue >= 70 && stenosisValue <= 100) {
    return 'severe';
  }
  return '';
}
