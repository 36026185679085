import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as ErrorIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/check-mark.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';

export const showToast = {
  default: (text: string) => {
    toast(text);
  },
  error: (text: string) => {
    toast.error(
      <>
        <i className="Toastify__icon">
          <ErrorIcon />
        </i>{' '}
        {text}
      </>
    );
  },
  success: (text: string) => {
    toast.success(
      <>
        <i className="Toastify__icon">
          <SuccessIcon />
        </i>{' '}
        {text}
      </>
    );
  },
  warning: (text: string) => {
    toast.warning(
      <>
        <i className="Toastify__icon">
          <WarningIcon />
        </i>{' '}
        {text}
      </>
    );
  },
  info: (text: string) => {
    toast.info(
      <>
        <i className="Toastify__icon">
          <InfoIcon />
        </i>{' '}
        {text}
      </>
    );
  },
  dark: (text: string) => {
    toast.dark(text);
  },
};

export default showToast;
