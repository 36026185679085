import React from 'react';
import PageTitle from '../components/PageTitle/PageTitle';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <>
      <PageTitle title="Not found" />
      <div className="ui main text container">
        <h1>Page not found</h1>
        <p>Sorry, we could not find what you are looking for.</p>
        <p>
          Return to the <Link to="/">home page</Link>.
        </p>
      </div>
    </>
  );
};

export default NotFound;
