// NOTE: This currently relies on the slice methods set up in the inherited vtkInteractorStyleMPRSlice.
import { InteractionOperations } from './customBase';
import { vtkEvent, vtkPublicAPI, vtkModel } from '../../ReactVTKJSTypes';

/**
 * Start the window level operation.
 */
function onStart(publicAPI: vtkPublicAPI, model: vtkModel, callData: vtkEvent) {
  model.interactionOperation = InteractionOperations.SLICE;

  // Get the current mouse position.
  model.previousPosition = callData.position;
}

/**
 * Adjust the slice based on the mouse movement.
 * 1) Move the cachedWorldCrosshairPosition on each api.
 * 2) The view needs to be redrawn for this view.
 * 3) The crosshairs need to be redrawn on the other views.
 * 4) We need to send a crosshairs moved message via a callback.
 */
function onMove(publicAPI: vtkPublicAPI, model: vtkModel, callData: vtkEvent) {
  const position = callData.position;
  if (!position) {
    return;
  }

  // Get the change in mouse Y position.
  const dy = position.y - model.previousPosition.y;

  // Get the slice range.
  const range = publicAPI.getSliceRange();

  // Get the camera's current slice (distance of the focal point along the camera's direction of projection.
  let slice = publicAPI.getSlice();

  // Adjust this constant to adjust how much the slice changes per pixel of mouse movement.
  const scrollSpeed = 0.001;

  // Adjust the slice and limit the range.
  slice += dy * scrollSpeed * (range[1] - range[0]);
  if (slice < range[0]) slice = range[0];
  if (slice > range[1]) slice = range[1];

  // Actually adjust the slice.
  publicAPI.scrollToSlice(slice);

  // Remember this mouse position.
  model.previousPosition = position;
}

/**
 * Initialize any additional publicAPI functions we may need.
 */
function initialize(publicAPI: vtkPublicAPI, model: vtkModel) {}

const DEFAULT_VALUES = {};

const defaults = {
  onStart,
  onMove,
  initialize,
  DEFAULT_VALUES,
};
export default defaults;
