import { useEffect, useRef } from 'react';

const useMousePositionRef = () => {
  const mousePosition = useRef<{
    x: number | null;
    y: number | null;
  }>({ x: null, y: null });

  const updateMousePosition = (ev: MouseEvent) => {
    mousePosition.current.x = ev.clientX;
    mousePosition.current.y = ev.clientY;
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);

    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  return mousePosition;
};

export default useMousePositionRef;
