import * as PIXI from 'pixi.js-legacy';
import Tess2 from 'tess2';

// Remember the original triangulate method.
// @ts-ignore
const defaultTriangulate = PIXI.graphicsUtils.buildPoly.triangulate;

/**
 * Tess2 can be used to work arounds the shortfalls of the default PIXI.Graphics polygon tesselation.
 * It allows for self intersection and holes in a complex polygon shape.
 * This function takes in the graphicsData.points and outputs the new tesselation in graphicsGeometry.
 */
const triangulate = (graphicsData: any, graphicsGeometry: any) => {
  try {
    if (graphicsData.points.length >= 6) {
      // Tesselate.
      const tesselation = Tess2.tesselate({
        contours: [graphicsData.points],
        windingRule: Tess2.WINDING_ODD,
        elementType: Tess2.POLYGONS,
        polySize: 3,
        vertexSize: 2,
      });

      // Set the triangle indices.
      graphicsGeometry.indices = tesselation.elements;

      // Set the verticies.
      graphicsGeometry.points = tesselation.vertices;
    }
  } catch (error) {
    // Tess2 does fire an assert occasionally, it seems to be when the points provided are linear.
    // console.warn('Tess2: unable to tesselate points', graphicsData.points, error);
  }
};

/**
 * Use TessGraphics in place if PIXI.Graphics to enable better tesselation of polygons.
 */
export class TessGraphics extends PIXI.Graphics {
  render(renderer: PIXI.Renderer) {
    // Override the default Earcut triangulation with Tess2.
    // TODO: This temporary override approach should be ok but it's not 100% ideal.
    // @ts-ignore
    PIXI.graphicsUtils.buildPoly.triangulate = triangulate;

    // Render the polygon, this will call PIXI.graphicsUtils.buildPoly.triangulate internally.
    super.render(renderer);

    // Restore the default Earcut triangulation.
    // @ts-ignore
    PIXI.graphicsUtils.buildPoly.triangulate = defaultTriangulate;
  }
}
